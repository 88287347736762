//src.js
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
// import Services from "./components/Services";
import "./App.css";
import AboutUs from "./components/About/AboutUs";
import Home from "./components/Home/Home";
import Header from "./components/Header/Header";
import Careers from "./components/Careers/Careers";
import ContactUs from "./components/Contact/ContactUs";
import Footer from "./components/Footer/Footer";
import Solution from "./components/Solution/Solution";

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <main>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/contact_page" element={<ContactUs />} />
            {/* <Route path="/services" element={<Services />} /> */}
            <Route
              path="/careers_page"
              element={<Careers />}
            />
            <Route path="/about_us" element={<AboutUs />} />
            <Route path="/solutions_page" element={<Solution />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;

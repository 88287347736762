import React from "react";
import Trust from "../../assets/icons/Trust.png";
import Ethics from "../../assets/icons/Ethics.png";
import Global from "../../assets/icons/Global.png";
import diversion from "../../assets/icons/diversion.png";
import Innovation from "../../assets/icons/Innovation.png";

const WhatWeDo = () => {
  return (
    <section className="container sm:mx-auto md:mx-[5%] mt-20 mb-10 px-4">
      <h1 className="mb-10 text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-bold">
        What Sets Us Apart<span className="text-[#FF983A]">?</span>
      </h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-10">
        {[
          { src: Trust, alt: "Trust", title: "Trust" },
          { src: Innovation, alt: "Innovation", title: "Innovation" },
          {
            src: Ethics,
            alt: "Ethics & Integrity",
            title: "Ethics & Integrity",
          },
          {
            src: diversion,
            alt: "Diversity & Inclusion",
            title: "Diversity & Inclusion",
          },
          {
            src: Global,
            alt: "Passion for Automation",
            title: "Passion for Automation",
          },
        ].map((item, index) => (
          <div
            key={index}
            className="w-full h-full max-w-xs mx-auto shadow-custom-lg rounded-lg space-y-4 p-5 mb-5 flex flex-col items-center hover:scale-105 transition-all ease-in-out duration-500"
          >
            <div className="w-20 h-20 bg-[#B4E5FA] rounded-full flex items-center justify-center p-3">
              <img
                src={item.src}
                alt={item.alt}
                className="w-full h-full object-contain"
              />
            </div>
            <h4 className="text-center text-[#A7672D] font-bold text-xl">{item.title}</h4>
          </div>
        ))}
      </div>
    </section>
  );
};

export default WhatWeDo;

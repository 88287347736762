import React from "react";

const Success = () => {
  return (
    <div
      class="p-4 mt-10 text-lg text-green-800 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-green-400"
      role="alert"
    >
      <span class="font-semibold text-xl mr-2">Submitted</span>Thank you for
      your interest, your message has been successfully delivered.
    </div>
  );
};

export default Success;

import React from "react";
import vijay from "../../assets/vijay.png";
import Vector from "../../assets/Vector.svg";
import manoj from "../../assets/manoj.png";

const advisors = [
  {
    name: "Mr. Manoj Joshi",
    role: "MIT CTO Certification, Technology Strategy, IT Leadership, Governance",
    image: manoj,
    linkedin: "http://linkedin.com/in/manojjoshisj",
  },
  {
    name: "Dr. Vijayraghavan Ramaiah",
    role: "Nuclear Medicine Consultant DNB, FASNC, RSO (NM)",
    image: vijay,
    linkedin: "http://linkedin.com/in/vijayaraghavan-ramaiah-781b6912",
  },
];

const MeetAdvisor = () => {
  return (
    <section className="bg-gradient-to-t from-[#DCEFFF] to-[#F5FAFF] mt-16 py-10 px-10 rounded-xl shadow-sm">
      <div className="container mx-auto px-4 lg:px-0">
        <h1 className="text-3xl lg:text-5xl font-bold mb-3 lg:mb-5">
          Meet Our Advisors
        </h1>
        <img src={Vector} alt="vector" className="w-full lg:w-[28rem] mb-12" />
        <div className="flex flex-col lg:flex-row justify-center items-center gap-8 lg:gap-12">
          {advisors.map((advisor, index) => (
            <div key={index} className="max-w-[450px] lg:w-[413px] py-3 sm:py-5 bg-white shadow-custom-lg rounded-xl hover:scale-105 transition-all ease-in-out duration-200">
              <img src={advisor.image} className="w-[222px] h-[225px] mx-auto" alt={advisor.name} />
              <div className="bg-[#616161] text-white font-bold text-center py-2 mt-3">
                {advisor.name}
              </div>
              <div className="mx-auto py-3 text-center px-5">
                <p className="w-[20rem] mx-auto">{advisor.role}</p>
                <br />
                <a href={advisor.linkedin} target="_blank" rel="noreferrer" className="px-3 py-2 bg-blue-500 rounded-md text-white font-semibold">
                  LinkedIn
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default MeetAdvisor;

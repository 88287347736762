import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import callIcon from "../../assets/icons/callicon.png";
import locationIcon from "../../assets/icons/locationicon.png";
import emailIcon from "../../assets/icons/emailicon.png";
import Success from "./Success";
import Loading from "../Loading";
import Failed from "./Failed";

const ContactUs = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      const response = await axios.post(
        "https://sprhava.com/sprhava_web/api/contactus_save.php",
        data,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        setLoading(false);
        setSuccess(true);
        reset();
      } else {
        console.error("Error submitting data:", response.statusText);
        setLoading(false);
        setSuccess(false);
        setError(response.statusText);
      }
    } catch (error) {
      console.error("Error submitting data:", error);
      setLoading(false);
      setSuccess(false);
      setError(error.message);
    }
  };

  const contactLeftRef = useRef(null);
  const contactFormRef = useRef(null);

  useEffect(() => {
    if (contactLeftRef.current && contactFormRef.current) {
      const contactFormHeight = contactFormRef.current.clientHeight;
      contactLeftRef.current.style.height = `${contactFormHeight}px`;
    }
  }, []);

  return (
    <div>
      {loading ? (
        <Loading />
      ) : (
        <div className="flex flex-col md:flex-row justify-between items-stretch p-5 mt-32 bg-white font-sans gap-0">
          <div
            className="w-full md:w-2/5 bg-black text-white p-10 rounded-lg flex flex-col justify-around items-center text-center box-border"
            ref={contactLeftRef}
          >
            <h1 className="text-blue-500 mb-5 text-2xl font-bold">
              Contact us!
            </h1>
            <p className="mb-5 text-lg">
              Contact us about anything related to our company or services.
              We'll do our best to get back to you as soon as possible.
            </p>
            <div className="mt-5 flex flex-col gap-7 justify-around items-start leading-6">
              <div className="flex gap-2.5 items-center justify-center mb-2.5">
                <img src={locationIcon} alt="Location Icon" className="w-7" />
                <p className="m-0 leading-none">
                  Raintaler Str. 81539, Munich, Germany
                </p>
              </div>
              <div className="flex gap-2.5 items-center justify-center mb-2.5">
                <img src={callIcon} alt="Phone Icon" className="w-7" />
                <p className="m-0 leading-none">+49 89 37012778</p>
              </div>
              <div className="flex gap-2.5 items-center justify-center mb-2.5">
                <img src={emailIcon} alt="Email Icon" className="w-7" />
                <p className="m-0 leading-none">info@sprhava.com</p>
              </div>
            </div>
            <iframe
              title="address_map"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3153.205025312168!2d-122.08624628468128!3d37.42199997982508!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x808fb760af110cfb%3A0x44b96db7fb2b01!2s3575%20Buena%20Vista%20Ave%2C%20Santa%20Clara%2C%20CA%2095011%2C%20USA!5e0!3m2!1sen!2s!4v1591427694248!5m2!1sen!2s"
              width="100%"
              height="300"
              frameBorder="0"
              className="border-0"
              allowFullScreen=""
              aria-hidden="false"
              tabIndex="0"
            ></iframe>
          </div>

          <form
            onSubmit={handleSubmit(onSubmit)}
            className="w-full md:w-3/5 bg-gray-100 p-10 rounded-lg shadow-md box-border"
            ref={contactFormRef}
          >
            <label className="block mt-2.5">Enter Full Name *</label>
            {errors.fullName && (
              <p className="text-red-500 text-xs mt-2 mb-2">
                {errors.fullName.message}
              </p>
            )}
            <input
              {...register("fullName", { required: "Full Name is required" })}
              className="w-full p-2.5 mt-1 mb-5 border border-gray-300 rounded-md"
            />

            <label className="block mt-2.5">Phone Number *</label>
            {errors.phoneNumber && (
              <p className="text-red-500 text-xs mt-2 mb-2">
                {errors.phoneNumber.message}
              </p>
            )}
            <div className="flex justify-between">
              <select
                {...register("countryCode", {
                  required: "Country Code is required",
                })}
                className="w-1/3 p-2.5 mt-1 mb-5 border border-gray-300 rounded-md mr-2.5"
              >
                <option value="+1"> Canada (+1)</option>
                <option value="+1"> USA (+1)</option>
                <option value="+49"> Germany (+49)</option>
                <option value="+39"> Italy (+39)</option>
                <option value="+81"> Japan (+81)</option>
                <option value="+61"> Australia (+61)</option>
              </select>
              <input
                {...register("phoneNumber", {
                  required: "Phone Number is required",
                  pattern: {
                    value: /^[0-9]+$/,
                    message: "Valid Phone Number is required",
                  },
                })}
                className="w-2/3 p-2.5 mt-1 mb-5 border border-gray-300 rounded-md"
              />
            </div>

            <label className="block mt-2.5">Enter Email *</label>
            {errors.email && (
              <p className="text-red-500 text-xs mt-2 mb-2">
                {errors.email.message}
              </p>
            )}
            <input
              type="email"
              {...register("email", { required: "Email is required" })}
              className="w-full p-2.5 mt-1 mb-5 border border-gray-300 rounded-md"
            />

            <label className="block mt-2.5">Company</label>
            <input
              {...register("company")}
              className="w-full p-2.5 mt-1 mb-5 border border-gray-300 rounded-md"
            />

            <label className="block mt-2.5">Subject *</label>
            {errors.subject && (
              <p className="text-red-500 text-xs mt-2 mb-2">
                {errors.subject.message}
              </p>
            )}
            <input
              {...register("subject", { required: "Subject is required" })}
              className="w-full p-2.5 mt-1 mb-5 border border-gray-300 rounded-md"
            />

            <label className="block mt-2.5">Question *</label>
            {errors.question && (
              <p className="text-red-500 text-xs mt-2 mb-2">
                {errors.question.message}
              </p>
            )}
            <textarea
              {...register("question", { required: "Question is required" })}
              className="w-full p-2.5 mt-1 mb-5 border border-gray-300 rounded-md"
            ></textarea>

            <button
              type="submit"
              className="max-w-1/3 min-w-1/5 p-2.5 bg-gradient-to-r from-blue-400 to-gray-400 text-white border-none rounded-md cursor-pointer text-lg hover:from-blue-400 hover:to-gray-600"
            >
              Submit
            </button>
            {success && <Success />}
            {error && <Failed />}
          </form>
        </div>
      )}
    </div>
  );
};

export default ContactUs;

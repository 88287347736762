import React from "react";

const data = [
  {
    id: "01",
    title: "Seamlessly Empower",
    description:
      "We bring the right talent to meet the right skills to help and grow innovative ideas in developing products for future.",
  },
  {
    id: "02",
    title: "Initiatives",
    description:
      "We follow agile approach to run in short cycles and iterations, progress in small steps hence there is high possibility to react selectively and quickly to dynamic changes.",
  },
  {
    id: "03",
    title: "Objectively Maintain",
    description:
      "We as leadership team inspire our teams through taking equal share in problem solving by brainstorming, talking to right experts, troubleshooting the problem together to ensure the problem get resolved at the earliest.",
  },
];

const HowWeWork = () => {
  return (
    <section className="container sm:mx-auto md:mx-[5%] mt-20 mb-10 px-4">
      <h1 className="mb-10 text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-bold">
        How We Work<span className="text-[#FF983A]">?</span>
      </h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10">
        {data.map((item) => (
          <div key={item.id} className="relative">
            <div className="w-full sm:w-[413px] min-h-[429px] space-y-6 px-5 py-2 shadow-custom-lg rounded-2xl hover:scale-90 transition-all duration-500">
              <h1 className="text-[128px] text-[#8B8B8B]">{item.id}</h1>
              <div className="w-full sm:w-[310px] h-[57px] mx-auto space-y-5 px-5 sm:px-0">
                <h3 className="text-[#A7672D] text-xl font-bold">{item.title}</h3>
                <p className="text-[#595959] text-[16px]">{item.description}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default HowWeWork;

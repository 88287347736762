import React from "react";
import Project1 from "../../assets/Images/Project1.png";
import Project2 from "../../assets/Images/Project2.png";
import { Link } from "react-router-dom";
const Endevours = () => {
  return (
    <section className="my-10 lg:px-16 px-5">
      <h1 className="text-[64px] font-bold">
        Our Current Endeavors
        <span className="text-[#FF983A]">!</span>
      </h1>
      <div className="mt-10 flex flex-col items-center sm:justify-evenly sm:flex-row flex-wrap md:gap-10">
        <div className="relative max-w-[600px] h-[700px] bg-gradient-to-t from-[#F1F1F1] to-[#C5EEFF] rounded-xl shadow-lg p-10 flex flex-col md:mb-0 mb-10">
          <img src={Project1} alt="project 1" className="h-[234px]" />
          <div className="flex flex-col flex-grow justify-between mt-5">
            <div className="space-y-8">
              <h1 className="text-[27px] text-[#2B48E0] font-medium">
                Let us delve into the fascinating world of AI smart glasses.
              </h1>
              <p className="text-[16px] w-[345px] text-justify text-[#464646]">
                These innovative glasses, getting developed by sprhava, combine
                cutting-edge technology with everyday eyewear. Here’s what you
                need to know: The AI smart glasses by sprhava.
              </p>
            </div>
            <div className="flex justify-end items-center gap-5 mt-4 hover:scale-90 transition-all ease-in-out duration-500">
              <h1 className="text-[#4858E7]">Learn More</h1>
              <Link
                to="/"
                className="material-symbols-outlined bg-gradient-to-r from-[#E0E0E0] to-[#BDBCBC] text-4xl rounded-full px-5 py-4 text-[#777777] hover:text-[#2B48E0] "
              >
                east
              </Link>
            </div>
          </div>
        </div>
        <div className="relative max-w-[600px] h-[700px] bg-gradient-to-t from-[#F1F1F1] to-[#C5EEFF] rounded-xl shadow-lg p-10 flex flex-col">
          <img src={Project2} alt="project 2" className="h-[234px]" />
          <div className="flex flex-col flex-grow justify-between mt-5">
            <div className="space-y-8">
              <h1 className="text-[27px] text-[#2B48E0] font-medium">
                sprhava Texture Analysis for Cancer Using PET, CT, and PET-CT
                Scans and Deployment to an Android App.
              </h1>
              <p className="text-[16px] w-[345px] text-justify text-[#464646]">
                spharva’s Objective is to develop a texture analysis framework
                for cancer classification using CT scans, focusing on the
                analysis of image texture to identify tumor characteristics.
              </p>
            </div>
            <div className="flex justify-end items-center gap-5 mt-4 hover:scale-90 transition-all ease-in-out duration-500">
              <h1 className="text-[#4858E7] ">Learn More</h1>
              <a
                href="/"
                className="material-symbols-outlined bg-gradient-to-r from-[#E0E0E0] to-[#BDBCBC] text-4xl rounded-full px-5 py-4 text-[#777777] hover:text-[#2B48E0] "
              >
                east
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Endevours;

import React from "react";
import downRightArrow from "../../assets/icons/Down_Right_arrow.png";
import Team from "./Team";
import Goal from "./Goal";
import "./about.css";

const AboutUs = () => {
  return (
    <section className="mt-24">
      <div className="bg-black bg-about md:bg-large-about h-screen bg-cover md:bg-center bg-no-repeat flex items-center justify-start p-3 md:p-20 relative">
        <div className=" absolute md:top-[5rem] top-[10rem]">
          <p className="text-[5rem] text-wrap md:text-[8rem] text-white font-bolder">
            Invent your <br />
            <i className="absolute md:top-[6rem] top-[8rem] md:text-[10rem] text-[8.5rem] font-thin font-itc-franklin-gothic-lt text-transparent sm:gradient-mask [-webkit-text-stroke:1px_#8c7aff]">
              Future
            </i>{" "}
            <p className="absolute text-[8rem] md:top-[15rem] top-[20rem]">
              Today{" "}
            </p>
          </p>
        </div>
      </div>
      <div className="px-[5%] bg-[#f1f6ff]">
        <div className=" md:py-20 py-10  md:flex md:justify-evenly">
          <div className="flex gap-3 mb-8">
            <h1 className="text-4xl font-bold">Our Story</h1>
            <span>
              <img src={downRightArrow} width={40} alt="down_right_arrow" />
            </span>
          </div>
          <div className="space-y-5 text-justify md:w-1/2 w-full">
            <p>
              sprhava is an evolving Bootstrap AI start-up with a key focus on
              automation disrupting the industry with ground-breaking solutions
              and business ideas. Our goal is to provide the best automation
              solution for Health Tech industry aiming to enhance both humanity
              as well as the world at large. We work on futuristic products and
              projects providing support in Artificial Intelligence, Machine
              Learning, and Deep Learning. Our team members are technology
              veterans committed to delivering the best support possible.
            </p>
            <p>
              We are a Germany based company. We believe in initiating and
              sustaining long term relationships with our clients that helps
              both the parties in producing innovative solutions. We operate on
              a strong foundation based on certain principles and values echoed
              throughout our company. sprhava operates on trust, integrity,
              ethics, embraces diversity, makes decisions based on inclusion,
              and possesses a deep passion for spearheading future automation.
            </p>
          </div>
        </div>
      </div>
      <Goal />
      <Team />
    </section>
  );
};

export default AboutUs;

import React from "react";
import ourVision from "../../assets/vision.png";
import ourValues from "../../assets/values.png";
import ourCapabilities from "../../assets/capabilities.png";

const Goal = () => {
  return (
    <section className="md:px-28 md:py-24 py-14 w-[80%] mx-auto">
      {/* Our Values Section */}
      <div className="mb-5 md:mb-0 md:flex md:flex-row sm:flex-col justify-between items-center shadow-lg md:shadow-none rounded-xl py-5 px-5 md:p-0">
        <img
          src={ourValues}
          className="md:w-[462px] md:h-[319px]"
          alt="Our Values"
        />
        <div className="text-justify mt-4 md:mt-0 md:pl-20">
          <h1 className="text-4xl font-bold mb-3">Our Values</h1>
          <div className="space-y-3">
            <p>
              <span className="font-bold">Trust:</span> We build lasting
              relationships with our clients based on trust.
            </p>
            <p>
              <span className="font-bold">Ethics and Integrity:</span> Trust is
              at the core of everything we do. We uphold the highest ethical
              standards, ensuring transparency and reliability.
            </p>
            <p>
              <span className="font-bold">Diversity and Inclusion:</span> Our
              team reflects a rich tapestry of backgrounds, cultures, and
              perspectives. We believe diversity fuels creativity and drives
              innovation.
            </p>
          </div>
        </div>
      </div>

      {/* Our Capabilities Section */}
      <div className="mb-5 md:mb-0 md:flex md:flex-row sm:flex-col gap-10 items-center shadow-lg md:shadow-none rounded-xl py-5 px-5 md:p-0">
        <div className="text-justify md:pr-20 py-5">
          <h1 className="text-4xl font-bold mb-3">Our Capabilities</h1>
          <div className="space-y-3">
            <p>
              <span className="font-bold">Innovation:</span> We thrive on
              groundbreaking ideas. Our solutions challenge the status quo,
              pushing the boundaries of what’s possible.
            </p>
            <p>
              <span className="font-bold">Passion for Automation:</span> We’re
              not just building products; we’re shaping the future. Our passion
              for automation fuels our relentless pursuit of excellence.
            </p>
          </div>
        </div>
        <img
          src={ourCapabilities}
          className="md:w-[462px] md:h-[319px]"
          alt="Our Capabilities"
        />
      </div>
    </section>
  );
};

export default Goal;

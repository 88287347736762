import React from "react";
import { Link } from "react-router-dom";
import LinkedInIcon from "../../assets/icons/linkedin.png";
import Logo from "../../assets/Logo.png";

const Footer = () => {
  const handleLogoClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <footer className="bg-gray-900 text-white py-8">
      <div className="px-4">
        <div className="flex flex-col items-start">
          <div className="flex flex-col md:flex-row md:justify-between items-center md:items-center lg:gap-[22rem] md:gap-[10rem] sm:gap-[2rem] gap-5">
            <Link to={"/"} onClick={handleLogoClick}>
              <img src={Logo} alt="logo" className="w-[175px] h-[45px]" />
            </Link>
            <div className="flex flex-col md:flex-row md:[2rem] lg:gap-[3rem] gap-5 text-xl font-bold">
              <Link
                to="/solutions_page"
                className="text-white"
                onClick={handleLogoClick}
              >
                Solutions
              </Link>
              <Link
                to="/contact_page"
                className="text-white"
                onClick={handleLogoClick}
              >
                Contact us
              </Link>
              <Link
                to="/about_us"
                className="text-white"
                onClick={handleLogoClick}
              >
                About us
              </Link>
              <Link
                to="/careers_page"
                onClick={handleLogoClick}
                className="text-white"
              >
                Careers
              </Link>
              {/* <a
                href="/terms"
                target="_blank"
                rel="noopener noreferrer"
                className="text-white"
              >
                Terms
              </a> */}
            </div>
          </div>
          <div className="bg-[#797979] w-full lg:w-[45rem] md:w-[35rem] sm:w-[30rem] md:mx-[20%] lg:mx-[30%] sm:mx-[10%] p-10 rounded-lg mt-10 md:mt-0">
            <div className="flex flex-col md:flex-row md:justify-between md:items-center">
              <div className="space-y-5">
                <div className="flex items-center gap-3">
                  <span className="material-symbols-outlined">location_on</span>
                  <p>Raintaler Str. 81539, Munich, Germany</p>
                </div>
                <div className="flex items-center gap-3">
                  <span className="material-symbols-outlined">call</span>
                  <p>+49 89 37012778</p>
                </div>
              </div>

              <div className="mt-4 md:mt-0">
                <p className="font-bold mb-2">Follow us on</p>

                <Link to={"https://www.linkedin.com/company/sprhava-linkedin/"}>
                  <div className="flex items-center gap-2">
                    <img
                      src={LinkedInIcon}
                      className="w-5 h-5"
                      alt="LinkedIn"
                    />
                    <Link
                      to="https://www.linkedin.com/company/sprhava-linkedin/"
                      className="font-semibold text-white  hover:text-black"
                    >
                      LinkedIn
                    </Link>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

import React from "react";
import cus1 from "../../assets/cus1.png";
import cus2 from "../../assets/cus2.png";
import cus21 from "../../assets/cus21.png";
import Vector from "../../assets/Vector.svg";

const Partners = () => {
  return (
    <section className="px-4 md:px-10 py-8 md:py-20">
      <div className="text-center md:text-left mb-20">
        <h1 className="text-4xl md:text-5xl font-bold mb-6">
          Our Esteemed Partners
        </h1>
        <img
          src={Vector}
          alt="vector"
          className="w-full md:w-[30rem] mx-auto md:mx-0 mb-6"
        />
        <p className="text-lg md:text-xl max-w-[50rem] mx-auto md:mx-0 mb-10 md:mb-5 px-4 md:px-0">
          At sprhava, we believe in the power of collaboration. Our partnerships
          with leading NGOs, companies, and organizations help us drive
          innovation and deliver exceptional solutions.
        </p>
      </div>

      <div className="flex items-center lg:items-start justify-between md:mb-24 mb-16 flex-col lg:flex-row gap-28 lg:gap-3">
        <div className="relative lg:w-1/2 ">
          <img src={cus1} alt="cus1" className="w-full lg:w-3/4" />
          <img
            src="https://samarthanam.org/wp-content/uploads/2023/10/samarthanam-logo.jpg"
            alt="samarthanam Logo"
            className="absolute top-[115%] left-1/2 lg:left-[40%] -translate-x-1/2 -translate-y-full w-1/2 rounded-lg shadow-lg"
          />
        </div>
        <div className="w-full lg:w-1/2 lg:px-5 px-3 ">
          <h1 className="font-bold text-2xl lg:text-3xl mb-4 text-center lg:text-left">
            Samarthanam Trust (NGO For The Disabled)
          </h1>
          <p className="text-justify text-xl">
            Is a leading non-profit dedicated to empowering individuals with
            disabilities based in India.
            <br />
            <br />
            Founded in 1997, they focus on education, employment, sports, and
            rehabilitation to promote an inclusive society.
            <br />
            <br /> Through their comprehensive approach, they advocate for the
            rights and inclusion of people with disabilities, significantly
            impacting countless lives across India.
            <br />
            <br />
            Notable initiatives include a sports academy for athletes with
            disabilities and various educational and vocational training
            programs.
          </p>
        </div>
      </div>

      <div className="flex items-center lg:items-start justify-between lg:flex-row gap-28 lg:gap-3 flex-col-reverse lg:mt-20">
        <div className="w-full lg:w-3/4 lg:px-5 lg:mr-20">
          <h1 className="font-bold text-2xl lg:text-3xl mb-4 text-center lg:text-left">
            Bayerischer Blinden- und Sehbehindertenbund e. V. (BBSB)
          </h1>
          <p className="text-justify text-xl">
            Is an organization that has been a cornerstone in advocating for the
            rights and welfare of visually impaired individuals in Bavaria since
            1916. <br />
            <br /> Their comprehensive efforts empower the blind and visually
            impaired community to lead independent and fulfilling lives. <br />
            <br /> Their efforts are pivotal in advancing accessibility,
            delivering indispensable support services, and fostering educational
            and vocational opportunities.
          </p>
        </div>
        <div className="relative lg:w-1/2">
          <img src={cus2} alt="cus2" className="w-full" />
          <img
            src={cus21}
            alt={cus21}
            className="absolute top-[115%] left-1/2 lg:left-[50%] -translate-x-1/2 -translate-y-full w-1/2 rounded-lg shadow-lg"
          />
        </div>
      </div>
    </section>
  );
};

export default Partners;

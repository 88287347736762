import React from "react";
import Solution_Banner from "../../assets/Images/Solution_Banner.png";
import Endevours from "./Endevours";
import WhatWeDo from "./WhatWeDo";
import HowWeWork from "./HowWeWork";
const Solution = () => {
  return (
    <section className="">
      <div
        style={{ backgroundImage: `url(${Solution_Banner})` }}
        className="h-[560px] bg-cover md:bg-center bg-right relative"
      >
        <div className="absolute top-[10rem] space-y-2 md:left-[10rem] md:w-[724px] text-white px-3">
          <h1 className="font-bold md:text-[5rem] text-[3rem]">
            What We Offer
          </h1>
          <p className="text-[#C1C1C1] md:ml-4 ml-2 text-justify">
            sprhava is an evolving AI company with key focus on automation
            disrupting the industry with groundbreaking solutions and business
            ideas. Our goal is to provide the best talent including incubation
            in the niche technology Artificial Intelligence, Machine Learning,
            Deep Learning. Our team members are technology veterans committed to
            providing the best support.
          </p>
        </div>
      </div>
      <Endevours />
      <WhatWeDo />
      <HowWeWork />
    </section>
  );
};

export default Solution;

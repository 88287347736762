import React from "react";
import Spec from "../../assets/specImg.png";

const ProjectHighlight = () => {
  return (
    <section className="bg-gradient-to-r from-[#E0F5FE] to-[#96E0FF] py-10 md:py-20 shadow-xl rounded-xl">
      <div className="flex flex-col md:flex-row justify-center items-center px-4 md:px-20">
        <div className="w-full md:w-1/2">
          <div className="space-y-6 md:space-y-10 mb-10">
            <p className="text-[16px] text-justify md:text-[20px] max-w-[35rem]">
              <strong>AI smart glasses </strong>for Visually Impaired
              individuals from the ground up based on Qualcomm Snapdragon silicon.
            </p>
            <h2 className="text-[30px] md:text-[40px] max-w-[45rem] text-[#2B48E0]">
              Let us delve into the fascinating world of
              <br />
              <strong>AI smart glasses.</strong>
            </h2>
          </div>

          <ul className="w-full md:px-5 px-10 md:w-auto list-disc text-lg flex flex-wrap justify-start md:justify-start gap-10 text-[#2B48E0] font-bold capitalize mb-5 sm:mb-0">
            <li>Text Reading</li>
            <li>Facial Recognition</li>
            <li>Product Identification</li>
            <li>Currency Notes Identification</li>
            <li>Voice Commands</li>
          </ul>

          {/* <a
            href="/"
            className="inline-block px-6 py-2 rounded-full border border-[#FA8734] font-bold text-[14px] md:text-[sm] text-gray-600"
          >
            Learn More
          </a> */}
        </div>
        <div className="w-full md:w-1/2 flex justify-center">
          <img
            src={Spec}
            alt="spectacle"
            className="w-full md:w-[500px] md:h-[400px] max-w-[90vw] md:max-w-none"
          />
        </div>
      </div>
    </section>
  );
};

export default ProjectHighlight;

import React from "react";
import HeroImage from "../../assets/banner.png";

import Partners from "./Partners";
import MeetAdvisor from "./MeetAdvisor";
import ProjectHighlight from "./ProjectHighlight";
import Aim from "./Aim";
import { Link } from "react-router-dom";
import Carousel from "./Carousel";

const Home = () => {
  return (
    <section className="relative mt-24">
      {/* <div className="bg-gradient-to-r from-[#FFBA7A] to-[#FFE975] h-[83px] w-full flex py-3 justify-center items-center">
        <h1 className="font-bold text-[1rem] md:text-[2rem] w-full text-center px-8">
          <Link
            to={"https://en.wemakefuture.it/next/exhibitors/"}
            className="hover:no-underline text-black"
          >
            <span className="text-[#5397FF]">sprhava </span>
            is heading to Bologna, Italy for the{""}
            <span className="text-[#A8004F] uppercase">
              ' We Make Future '<span className="text-black">, </span>
            </span>
            event!
            <span className="text-black"> June 13-15, 2024</span>
          </Link>
        </h1>
      </div> */}
      <div
        className="relative h-[70vh] md:h-[95vh] w-full bg-top bg-cover"
        style={{ backgroundImage: `url(${HeroImage})` }}
      >
        <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-60">
          <div className="text-center p-4 hover:text-[#3698FB] hover:scale-110 transition-all ease-in-out duration-500">
            <h3 className="text-[2rem] text-white hover:text-[#3698FB] md:text-[4rem] lg:text-[64px] font-bold mb-4 ">
              sprhava
            </h3>
            <h1 className="text-[#9F672A] text-[3rem] md:text-[6rem] lg:text-[128px] leading-none">
              AI smart glasses
            </h1>
            <h4 className="text-[#ffff] text-[1rem] md:text-[1.5rem] mt-4">
              Unobtrusive Access To The Visual World
            </h4>
          </div>
        </div>
      </div>

      <section className="md:px-20 py-10">
        <Carousel />
        <Aim />
        <ProjectHighlight />
        <Partners />
        <MeetAdvisor />
      </section>
    </section>
  );
};

export default Home;

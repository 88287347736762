import React from "react";
import ananth from "../../assets/ananth.png";
import jyotsana from "../../assets/jyotsana.png";
import varun from "../../assets/varun.png";
import Anup from "../../assets/Anup.png";
import praveen from "../../assets/praveen.png";
import Jovana from "../../assets/Jovana.jpg";
import Deepika from "../../assets/Deepika.jpg";
import Irfan from "../../assets/Irfan.jpg";

const teamMembers = [
  {
    name: "Ananthraj Narasappa",
    role: "Founder / CEO - Germany",
    image: ananth,
    linkedin: "https://www.linkedin.com/in/narasappa-ananthraj-28b8954b/",
  },
  {
    name: "Praveen Kumar",
    role: "Co-Founder / CTO - Global",
    image: praveen,
    linkedin: "https://www.linkedin.com/in/praveen-zummit/",
  },
  {
    name: "Jyothsna Anand",
    role: "Co-Founder / Operations Head - India",
    image: jyotsana,
    linkedin: "https://www.linkedin.com/in/jyothsnaanand/",
  },
  {
    name: "Varun Parameswara",
    role: "Co-Founder / Marketing Head - Germany",
    image: varun,
    linkedin: "https://www.linkedin.com/in/varun-parameswara-464991188/",
  },
  {
    name: "Anoop Mohan Siddalingeshwara",
    role: "Global Delivery Head - Germany",
    image: Anup,
    linkedin: "https://www.linkedin.com/in/ams14697/",
  },
  {
    name: "Mohamed Irfan PM",
    role: "Project Director - East Asia",
    image: Irfan,
    linkedin: "https://www.linkedin.com/in/mohamed-irfan-pm-96502ba2/",
  },
  {
    name: "Deepika Nalli Kumar",
    role: "Human Resource - India",
    image: Deepika,
    linkedin: "http://linkedin.com/in/deepika-nalli-kumar-b96777126",
  },
  {
    name: "Jovana Pavlović",
    role: "Human Resource - Serbia",
    image: Jovana,
    linkedin: "https://www.linkedin.com/in/jovana-pavlovi%C4%87-138082192/",
  },
];

const Team = () => {
  return (
    <section className="bg-[#f1f6ff] py-24 mb-20">
      <div className="mx-auto w-[80%]">
        <h1 className="text-3xl lg:text-5xl font-bold mb-8 sm:mb-10">
          Meet <span className="text-[#F29834]">The Team</span>
        </h1>
        <div className="flex flex-wrap flex-col md:flex-row justify-center items-center gap-8 lg:gap-12">
          {teamMembers.map((member, index) => (
            <div
              key={index}
              className="min-w-[300px] max-w-[450px] h-[400px] sm:h-auto py-3 sm:py-5 bg-white shadow-xl rounded-xl mx-auto hover:scale-105 transition-all ease-in-out duration-300 "
            >
              <img
                src={member.image}
                alt={member.name}
                className="h-[225px] mx-auto"
              />
              <div className="bg-[#616161] text-white text-center py-2 mt-3 text-wrap">
                <h1 className="tracking-widest">{member.name}</h1>
              </div>
              <div className="mx-auto py-5 text-center">
                <p>{member.role}</p>
                <br />
                <a
                  href={member.linkedin}
                  target="_blank"
                  rel="noreferrer"
                  className="px-3 py-2 bg-blue-500 rounded-md text-white font-semibold"
                >
                  LinkedIn
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Team;

import React from "react";

const Failed = () => {
  return (
    <div
      class="p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400"
      role="alert"
    >
      <span class="font-medium text-xl mr-2">Submission Failed</span>
      Please Try Again Later !
    </div>
  );
};

export default Failed;
